<template>
  <div class="reminder">
    <v-overlay v-model="showOverlay" :persistent="true" :absolute="true" />

    <v-alert
      :width="smAndDown ? '80%' : 450"
      :type="alertType"
      density="compact"
      closable
      :icon="false"
      class="waiting-alert mx-auto pl-0"
      @click:close="authStore.sessionTimer = null"
    >
      <vue-countdown
        :time="authStore.sessionTimer"
        v-slot="{ minutes, seconds }"
        @start="alertType = 'error'"
        @end="handelEndTime"
        @progress="checkCustomerRespond"
      >
        <div class="d-flex justify-space-between align-center text-xs">
          <div
            v-if="authStore.auth.user.sessionType === 'scheduled'"
            class="d-flex align-center"
          >
            <span> حان موعد جلستك مع المرشد </span>

            <v-btn
              v-if="route.name !== 'room'"
              variant="text"
              class="mx-2 px-0"
              color="success"
              @click="
                authStore.sessionTimer = null;
                sessionStore.getCurrentSessionId();
                sessionStore.sendLog('ENTERED');
                navigateTo('/room');
              "
              >الدخول إلى الجلسة</v-btn
            >
          </div>
          <span v-else-if="route.name === 'room'">
            يرجى الإنتظار سيتم بدء الجلسة بعد
          </span>
          <span v-else> في إنتظار قبول الطلب </span>
          <div class="counter-value">
            <span>
              {{ seconds >= 10 ? seconds : `0${seconds}` }}
            </span>
            <span v-if="minutes > 0">
              : {{ minutes >= 10 ? minutes : `0${minutes}` }}
            </span>
          </div>
        </div>
      </vue-countdown>
    </v-alert>
  </div>
</template>

<script setup>
import VueCountdown from '@chenfengyuan/vue-countdown';
import { useDisplay } from 'vuetify';
const { smAndDown } = useDisplay();

const emit = defineEmits(['endTimer']);

import { useAuthStore } from '~~/stores/AuthStore';
import { useSessionStore } from '~~/stores/SessionStore';

const sessionStore = useSessionStore();
const authStore = useAuthStore();

const alertType = ref('error');

const showOverlay = ref(true);

const currentTimer = ref(0);

const route = useRoute();

watch(
  () => authStore.sessionTimer,
  (newValue) => {
    if (newValue) {
      let fetchTimer = setInterval(() => {
        getCurrentSession();
      }, 10000);

      setTimeout(() => {
        clearInterval(fetchTimer);
      }, authStore.sessionTimer);
    }
  },
  { deep: true }
);

if (authStore.sessionTimer) {
  let fetchTimer = setInterval(() => {
    getCurrentSession();
  }, 10000);

  setTimeout(() => {
    clearInterval(fetchTimer);
  }, authStore.sessionTimer);
}

function getCurrentSession() {
  if (
    currentTimer.value >= 0 &&
    authStore.auth.user &&
    authStore.sessionTimer &&
    route.name !== 'room' &&
    authStore.auth.user.sessionType !== 'scheduled'
  ) {    
    // 
    authStore.getDirectSessionRequestNotificationData();
  } else if (
    currentTimer.value >= 0 &&
    authStore.auth.user &&
    authStore.sessionTimer &&
    route.name !== 'room' &&
    authStore.auth.user.sessionType === 'scheduled'
  ) {
    sessionStore.getCurrentSessionId();
  }
}

function checkCustomerRespond(data) {
  currentTimer.value = data.seconds;
}

function handelEndTime() {
  if (
    authStore.auth.user &&
    route.name !== 'room' &&
    ((authStore.auth.user.sessionType &&
      authStore.auth.user.sessionType !== 'scheduled') ||
      !authStore.auth.user.sessionType)
  ) {
    authStore.sessionTimer = null;
    authStore.pushNotificationData = {
      type: 'direct-session-request',
      status: 'rejected',
    };
  } else {
    authStore.sessionTimer = null;
  }
  emit('endTimer');
}
</script>
<style lang="scss">
.reminder {
  direction: ltr;
  .v-alert {
    z-index: 9999;

    .v-alert__close .v-btn {
      color: #fff !important;
    }
  }

  .waiting-alert {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
  }

  .counter-value {
    display: flex;
    justify-content: flex-end;
    width: 50px;
    max-width: 60px;
  }
}
</style>
